/* contactus.css */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .contact-us-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    padding: 2rem;
  }
  
  .contact-us h1 {
    font-size: 2rem;
    color: #0066cc;
    margin-bottom: 1rem;
  }
  
  .contact-us p {
    font-size: 1rem;
    color: #444;
    line-height: 1.5;
    margin-bottom: 1rem;
    max-width: 800px;
  }
  
  .contact-us ul {
    list-style-type: disc;
    margin-left: 2rem;
    text-align: left;
    max-width: 800px;
  }
  
  .contact-us ul li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 0.5rem;
  }

  

  /* termsandconditions.css */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .terms-conditions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    padding: 2rem;
  }
  
  .terms-conditions h1 {
    font-size: 2rem;
    color: #ff6600;
    margin-bottom: 1rem;
  }
  
  .terms-conditions p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
    margin-bottom: 1rem;
    max-width: 800px;
  }
  
  .terms-conditions ul {
    list-style-type: square;
    margin-left: 2rem;
    text-align: left;
    max-width: 800px;
  }
  
  .terms-conditions ul li {
    font-size: 1rem;
    color: #444;
    font-style: italic;
    margin-bottom: 0.5rem;
  }

  


  /* refundandcancellation.css */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .refund-cancellation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    padding: 2rem;
  }
  
  .refund-cancellation h1 {
    font-size: 2rem;
    color: #ff3333;
    margin-bottom: 1rem;
  }
  
  .refund-cancellation p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 1rem;
    max-width: 800px;
  }
  
  .refund-cancellation ul {
    list-style-type: square;
    margin-left: 2rem;
    text-align: left;
    max-width: 800px;
  }
  
  .refund-cancellation ul li {
    font-size: 1rem;
    color: #444;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  

  
