/* Login Page */
.login-page {
    display: flex;
    flex-direction: column; /* Default to column layout for smaller screens */
    height: 100vh;
    margin: 0;
  }
  
  /* Left Section */
  .left-section {
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    box-sizing: border-box;
    height: 100%;
  }
  
  .left-section h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .left-section p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .features-list {
    list-style: none;
    padding: 0;
  }
  
  .features-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1rem;
    transition: transform 0.3s ease, background-color 0.3s ease;
    padding: 5px;
  }
  
  .features-list li:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: scale(1.05);
    border-radius: 5px;
  }
  
  .features-list i {
    font-size: 1.5rem;
    color: white;
  }
  
  /* Right Section */
  .right-section {
    flex: 1;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    height: 100%;
    box-sizing: border-box;
  }
  
  .right-section h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .right-section p {
    color: #666;
    margin-bottom: 20px;
  }
  
  form {
    width: 100%;
    max-width: 400px;
  }
  
  label {
    font-size: 1rem;
    color: #333;
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  input:hover {
    box-shadow: 0 0 5px #6a11cb;
    transform: scale(1.02);
  }
  
  input:focus {
    box-shadow: 0 0 5px #6a11cb;
    outline: none;
  }
  
  button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  button:hover {
    background-color: #4e0fbf;
    transform: scale(1.05);
  }
  
  .divider {
    text-align: center;
    margin: 20px 0;
    color: #666;
  }
  
  .google-login {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background: white;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .google-login:hover {
    background-color: #f0f0f0;
    transform: scale(1.05);
  }
  
  .google-login i {
    font-size: 1.2rem;
    margin-right: 10px;
  }
  
  .signup-link {
    text-align: center;
    margin-top: 20px;
    color: #333;
  }
  
  .signup-link a {
    color: #6a11cb;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .signup-link a:hover {
    color: #2575fc;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .left-section {
      display: none; /* Hide left section on small screens */
    }
  
    .right-section {
      width: 100%;
      height: 100vh;
    }
  }
  
  @media (min-width: 1024px) {
    .login-page {
      flex-direction: row; /* Switch to row layout on large screens */
    }
  
    .left-section {
      flex: 1;
      display: flex; /* Display left section on large screens */
    }
  
    .right-section {
      flex: 1;
      padding: 40px;
    }
  }
  
